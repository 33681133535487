import React from 'react'

const About = () => (
  <>
    <h1>
        I need to put some information here!
    </h1>
  </>
)

export default About
